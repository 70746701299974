<template>
    <header>
        <nav v-bind:class="{ 'active': showNavbar, 'show-language-options': showLanguageOptions }">
            <div class="container">
                <router-link to="/"><BeexactLogo /></router-link>

                <div id="navbar-button" @click.stop="toggleNavbar()">
                    <div>Toggle</div>
                </div>
            </div>

            <div class="container-lg">
                <div class="navbar-toggle" v-touch:swipe="onSwipeNavbar">
                    <div class="main-menu">
                        <router-link to="/">Home</router-link>
                        <router-link to="/vacatures">Vacatures</router-link>
                        <router-link to="/over-ons">Over ons</router-link>
                        <router-link to="/contact">Contact</router-link>
                    </div>
                    <!-- 
                    <div class="language-button" @click.stop="toggleLanguage()">
                        {{ $t('common.language') }}
                    </div>
                    <div class="language-options">
                        <span class="back" @click.stop="toggleLanguage()"></span>
                        <a class="locale-nl" @click.stop="switchLanguage('nl')">Nederlands</a>
                        <a class="locale-de" @click.stop="switchLanguage('de')">Deutsch</a>
                        <a class="locale-en" @click.stop="switchLanguage('en')">English</a>
                    </div> -->
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import BeexactLogo from '@/components/BeexactLogo.vue'
import { setI18nLocale } from '@/i18n'

export default {
    components: {
        BeexactLogo,
    },
    data() {
        return {
            showNavbar: false,
            showLanguageOptions: false,
        }
    },
    methods: {
        toggleNavbar() {
            this.showNavbar = !this.showNavbar
            this.showLanguageOptions = false
        },
        toggleLanguage() {
            this.showLanguageOptions = !this.showLanguageOptions
        },

        onSwipeNavbar(direction) {
            if(this.showNavbar) {
                if(direction == 'left') {
                    this.showLanguageOptions = true
                } else if(direction == 'right') {
                    this.showLanguageOptions = false
                }
            }
        },

        switchLanguage(locale) {
            setI18nLocale(locale)
            this.showLanguageOptions = false
        }
    },
    watch: {
        '$route' () {
            if(this.showNavbar){
                this.showNavbar = false;
            }
        }
    }
}
</script>

<style>
header a {
    text-decoration: none;
    color: #000;
    font-size: 24px;
}
header a:hover,
header a.router-link-active {
    color: #FF7200;
}

body.orange header .logo path.color {
    fill:#fff;
}

@media (min-width: 768px) {
    header {
        height: auto;
        min-height: 220px;
    }
    header .logo {
        margin: 67px 0 83px 0;
    }
}

@media (max-width: 767px) {
    header {
        height: auto;
    }
    header .logo {
        height: 46px;
        width: auto;
        margin: 32px 0 32px 0;
    }
}

.show-language-options .language-options a:hover,
html[lang=nl] .show-language-options .language-options a.locale-nl,
html[lang=de] .show-language-options .language-options a.locale-de,
html[lang=en] .show-language-options .language-options a.locale-en {
    color: #FF7200;
}

/*  vanaf 992px normaal menu  */
@media (min-width: 992px) {
    header .container-lg {
        z-index: 1;
        position: relative;
        top: -220px;
    }
    .topMenu {
        position: absolute;
        right: 20px;
        top: 40px;

        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        color: #777;

        z-index: 3;
    }
    .topMenu a {
        line-height: 28px;
        font-size: 16px;
        display: inline-block;
        color: #777;
        margin-left:25px;
    }
    .topMenu a.router-link-active {
        font-weight:bold;
    }
    .language-button {
        position: absolute;
        right: 20px;
        top: 40px;

        cursor: pointer;
        font-size: 16px;
        line-height: 19px;
        color: #777;

        z-index: 3;

        width: 90px;
    }
    .language-options {
        display: none;
        right: 20px;
        top: 60px;
    }
    .language-options span.back {
        display: none;
    }
    .show-language-options .language-options {
        display: block;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 20px;

        width: 130px;
        background: #FFFFFF;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.15);
        padding: 50px 20px 20px 20px;
    }
    .show-language-options .language-options a {
        line-height: 28px;
        font-size: 16px;
        display: block;
        color: #777;
    }
    

    .language-button:after {
        content: "\e904";
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 10px;
        line-height: 10px;

        height: 10px;
        width: 10px;

        position: absolute;
        right: 0;
        top: 6px;
    }

    .main-menu {
        position: absolute;
        right: 10px;
        top: 86px;
        margin: 0;
    }
    .main-menu a {
        display: inline-block;
        padding: 10px;
    }

    nav #navbar-button {
        display: none;
    }
}
/*  tot 991px hamburger menu  */
@media (max-width: 991px) {
	header .container {
        z-index: 1;
        position: relative;
    }
    nav #navbar-button {
		display: block;
		position: absolute;

        right: 40px;
        top: 70px;
		
		margin: 0;
		width: 40px;
		height: 40px;
		cursor: pointer;
		
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	nav #navbar-button div {
		font-size: 0.0em;
		text-indent: 100%;
		white-space: nowrap;
		top: 18px;
		left: 4px;
	}
	
	nav #navbar-button div,
	nav #navbar-button div::before,
	nav #navbar-button div::after {
		-webkit-transition: 250ms all;
		transition: 250ms all;
		
		background: #000;
		width: 26px;
		height: 3px;
		position: absolute;
	}
	nav #navbar-button div::before,
	nav #navbar-button div::after {
		top: 0px;
		left: 0px;
		content: ' ';
		display: block;
	}
	nav #navbar-button div::before {
		-webkit-transform: translateY(-9px);
		-ms-transform: translateY(-9px);
		transform: translateY(-9px);
	}
	nav #navbar-button div::after {
		-webkit-transform: translateY(9px);
		-ms-transform: translateY(9px);
		transform: translateY(9px);
	}
	nav.active #navbar-button div {
		background: transparent;
	}

	nav.active #navbar-button div::before {
		-webkit-transform: translateY(0px) rotate(-45deg);
		-ms-transform: translateY(0px) rotate(-45deg);
		transform: translateY(0px) rotate(-45deg);

        background: #FF7200;
	}
	nav.active #navbar-button div::after {
		-webkit-transform: translateY(0px) rotate(45deg);
		-ms-transform: translateY(0px) rotate(45deg);
		transform: translateY(0px) rotate(45deg);

        background: #FF7200;
	}
	nav .navbar-toggle {
		-webkit-transition: 1250ms max-height;
		transition: 1250ms max-height;
	
		height: auto;
		overflow: hidden;
		position: relative;
		width: 100%;
		width: calc(100% + 40px);
		clear: both;
		max-height: 0px;

        background: #fff;

        margin: 0 -20px;
        z-index: 2;
	}
    
	nav.active .navbar-toggle {
		height: auto;
		max-height: 500px;
	}
    nav .navbar-toggle:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        pointer-events: none;

        top: 0;
        left: 0;
        height: 100%;
        width: calc(50vw + 11px);
        transform: skewX(-30deg);
        transform-origin: right top;
        
        z-index: 0;
    }
    body.orange nav .navbar-toggle:after {
        background: linear-gradient(0deg, rgba(255,255,255,1) 00%, rgba(237,237,237,1) 100%);
    }
    body.blank nav .navbar-toggle:after {
        background: linear-gradient(270deg, #EDEDED 5.9%, #FFFFFF 99.36%);
    }
    .language-button {
        display: block;
        padding: 10px 10px 20px 10px;
        text-align: center;
        font-size: 20px;
        margin-bottom: 23px;
    }
    .language-button:before {
        content: "\e900";
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 10px;
        line-height: 10px;

        height: 10px;
        width: 10px;

        vertical-align: middle;
    }
    .language-button:after {
        content: '';
        position: absolute;

        bottom: 0;
        height: 0;
        width: 60px;
        left: 50%;
        margin-left: -30px;

        border-top: 4px solid #ff7200;
    }
    .language-options {
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding: 20px 0 0 0;
    }
    .language-options a {
        display: block;
        padding: 10px;
        text-align: center;
        font-size: 20px;
    }
    .language-options:after {
        content: '';
        position: absolute;

        height: 0;
        width: 60px;
        left: 50%;
        margin: 10px 0 0 -30px;

        border-top: 4px solid #ff7200;
    }
    .language-options span.back {
        position: absolute;
        left: 20px;
        top: 20px;
        padding: 10px;

        cursor: pointer;
    }
    .language-options span.back:before {
        content: "\e902";
        
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        font-size: 20px;
        line-height: 20px;

        height: 20px;
        width: 15px;

        color: #ff7200;
    }
    .main-menu {
        z-index: 1;
        position: relative;
        padding: 20px 0 0 0;
    }
    .main-menu a {
        display: block;
        padding: 10px;
        text-align: center;
    }
    nav .navbar-toggle > * {
		-webkit-transition: 1250ms transform;
		transition: 1250ms transform;
        transform: translateX(0%)
    }
    nav.show-language-options .navbar-toggle > * {
        transform: translateX(-100%)
    }
}
@media (max-width: 767px) {
    nav .navbar-toggle:after {
        width: calc(50vw + 37px);
    }
    nav #navbar-button {
        top: 32px;
    }
}
@media (max-width: 575px) {
    nav .navbar-toggle:after {
        width: 200px;
    }
}


</style>