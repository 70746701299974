import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'

const i18n = createI18n()

async function loadLocaleMessages(locale) {
    // load locale messages with dynamic import
    const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    )

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages)

    return nextTick()
}

export function setupI18n() {
    var locale = localStorage.getItem('i18n/locale') || 'en'
    setI18nLocale(locale)
    return i18n
}

export function setI18nLocale(locale) {
    if(['nl','de','en'].indexOf(locale) == -1) {
        return false
    }

    localStorage.setItem('i18n/locale', locale)

    if(i18n.global.availableLocales.indexOf(locale) == -1) {
        loadLocaleMessages(locale)
    }

    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html').setAttribute('lang', locale)
}