import * as Vue from 'vue'
import * as VueRouter from 'vue-router'
import { h } from 'vue'
import { RouterView } from 'vue-router'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import Vue3TouchEvents from 'vue3-touch-events'
import VueSmoothScroll from 'vue3-smooth-scroll'

import { setupI18n } from './i18n'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/HomePage.vue'),
        meta: {
            layout: 'orange-home',
        },
    },
    {
        path: '/vacatures',
        component: { render: () => h(RouterView) },
        children: [
            {
                path: '',
                name: 'Vacatures',
                component: () => import('@/pages/VacanciesPage.vue'),
                meta: {
                    layout: 'blank',
                }
            },
            {
                path: '/vacatures/:id/details',
                name: 'Vacature details',
                component: () => import('@/pages/VacanciesDetailPage.vue'),
                meta: {
                    layout: 'blank',
                },
            },
            {
                path: '/vacatures/:id/apply',
                name: 'Solliciteren',
                component: () => import('@/pages/VacancyApplicationForm.vue'),
                meta: {
                    layout: 'orange-contact',
                },
            },
            {
                path: '/open-sollicitatie',
                name: 'Open sollicitatie',
                component: () => import('@/pages/VacancyApplicationOpenForm.vue'),
                meta: {
                    layout: 'orange-contact',
                },
            },
            {
                path: '/vacatures/bedankt',
                name: 'Bedankt',
                component: () => import('@/pages/VacancyThankYou.vue'),
                meta: {
                    layout: 'blank',
                },
            },
        ]
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/pages/ContactPage.vue'),
        meta: {
            layout: 'orange-contact',
        },
    },
    {
        path: '/over-ons',
        name: 'About us',
        component: () => import('@/pages/AboutPage.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/privacy-statement',
        name: 'PrivacyStatement',
        component: () => import('@/pages/PrivacyStatementPage.vue'),
        meta: {
            layout: 'blank',
        },
    },

    {
        path: '/:pathMatch(.*)*',
        redirect: '404',
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/pages/Error404Page.vue'),
        meta: {
            layout: 'blank',
        },
    },
]


const router = VueRouter.createRouter({
    // Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHistory(),
    routes, // short for `routes: routes`
    scrollBehavior(to, from) {
        // always scroll to top
        if(!from || to.path !== from.path) {
            return { top: 0 }
        }
        return
    },
})

router.beforeEach((to) => {
    if(to.meta.layout == 'orange-home') {
        document.body.classList.add('orange')
        document.body.classList.add('orange-home')
        document.body.classList.remove('orange-contact')
        document.body.classList.remove('blank')
    } else if(to.meta.layout == 'orange-contact') {
        document.body.classList.add('orange')
        document.body.classList.add('orange-contact')
        document.body.classList.remove('orange-home')
        document.body.classList.remove('blank')
    } else {
        document.body.classList.add('blank')
        document.body.classList.remove('orange')
        document.body.classList.remove('orange-home')
        document.body.classList.remove('orange-contact')
    }
})
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        // TODO vertalen
        document.title = to.name + ' | BeeXact.com'
    });
});

const i18n = setupI18n();

const app = Vue.createApp(App)

app.use(i18n)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)

app.use(Vue3TouchEvents)
app.use(VueSmoothScroll)

const axiosIns = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
})
app.use(VueAxios, axiosIns)


app.mount('#app')